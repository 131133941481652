<template>
    <div class="companyImport">
        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem>
                <router-link :to="{ name: 'CompaniesList' }">Companies</router-link>
            </CBreadcrumbItem>
            <CBreadcrumbItem active> Import Companies</CBreadcrumbItem>
        </teleport>

        <CCard>
            <CCardBody>
                <div class="companyImport__body">
                    <h2 class="companyImport__body__title">Company Import Wizard</h2>
                    <div class="companyImport__body__progress">
                        <div class="companyImport__body__progress__circle active" style="cursor: pointer"
                             @click="goStart">
                            <CIcon name="cil-file"/>
                            <div class="companyImport__body__progress__circle-text">
                                Choose Import <br/> Source
                            </div>
                        </div>

                        <div class="companyImport__body__progress__line">
                            <div class="companyImport__body__progress__line-bg"
                                 :style="`width: ${step > 0 ? 100 : 0}%`"/>
                        </div>

                        <CIcon :key="`arrow-1-${step}`" name="cilArrowRight"
                               :class="{ companyImport__body__progress__arrow: true, 'opacity-animation': step === 0 }"
                               style="color: #005d9d"/>

                        <div :class="`companyImport__body__progress__circle${ step > 0 ? ' active' : '' }`">
                            <CIcon name="cil-link"/>
                            <div class="companyImport__body__progress__circle-text">
                                Linking <br/> Process
                            </div>
                        </div>

                        <div class="companyImport__body__progress__line">
                            <div class="companyImport__body__progress__line-bg"
                                 :style="`width: ${step > 2 ? 100 : 0}%`">
                                <span v-if="step >= 2" style="margin-left: 5px; white-space: nowrap"></span>
                            </div>
                        </div>

                        <CIcon :key="`arrow-2-${step}`" name="cilArrowRight"
                               :class="{ companyImport__body__progress__arrow: true, 'opacity-animation': step === 1 }"
                               :style="`color: ${step > 0 ? '#005D9D' : '#ffffff'}`"/>

                        <div :class="`companyImport__body__progress__circle${ step > 2 ? ' active' : '' }`">
                            <CIcon name="cil-group"/>
                            <div class="companyImport__body__progress__circle-text">
                                Import <br/> Finished
                            </div>
                        </div>
                    </div>

                    <div v-if="step === 0" class="companyImport__body__modal mb-5">
                        <h2 class="companyImport__body__modal__title">Choose Company Import Source</h2>
                        <CButton color="primary" class="companyImport__body__modal__btn" @click="loadFile">
                            <CIcon name="cil-file"/>
                            Select Companies File
                        </CButton>
                        <input ref="inputfile" type="file" accept=".xls, .xlsx" hidden @change="handleChangeFile"/>
                    </div>

                    <div v-if="step === 1" class="companyImport__body__modal mb-5">
                        <h2 class="companyImport__body__modal__title">
                            Linking Companies to your account and sending invites
                        </h2>
                        <CButton color="primary" class="companyImport__body__modal__btn" @click="sendFile">
                            Start Linking Process
                        </CButton>
                    </div>

                    <div v-if="step === 2"
                         class="companyImport__body__modal companyImport__body__modal__spinner mt-5 mb-5 justify-content-center">
                        <CSpinner class="companyImport__body__modal__spinner" color="primary"/>
                    </div>

                    <div v-if="step === 3" class="companyImport__body__modal">
                        <h2 class="companyImport__body__modal__title">
                            {{ finished.total_connected_companies }} Companies Linked,
                            {{ finished.total_invited_companies }} Invites Send
                        </h2>
                        <CButton color="primary" class="companyImport__body__modal__btn"
                                 @click="$router.push({ name: 'CompaniesList' })">
                            View Companies
                        </CButton>
                        <h2 class="companyImport__body__modal__subtitle">Import Summary</h2>
                        <template v-if="finished">
                            <p class="companyImport__body__modal__text">
                                {{ finished.total_connected_companies }} of
                                {{ finished.total_document_rows }} Companies Successfully synced to
                                your list of Companies
                            </p>
                            <p class="companyImport__body__modal__text">
                                {{ finished.total_invited_companies }} Invitations have been
                                sent to join Contracts Connected
                            </p>
                            <p class="companyImport__body__modal__text">
                                Invited companies are added to your Companies after sign up
                            </p>
                        </template>
                    </div>

                    <div class="companyImport__body__footer">
                        <div class="companyImport__body__footer__link">
                            Need help getting started?
                        </div>
                        <div class="companyImport__body__footer__link">
                            <span class="companyImport__body__footer__link-item">View import guide</span>
                            <a href="/companies.xls" class="companyImport__body__footer__link-item">
                                Download sample spreadsheet
                            </a>
                            <span class="companyImport__body__footer__link-item">View the FAQ</span>
                        </div>
                    </div>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import CompanyInvitations from "@/api/v2/endpoints/CompanyInvitations";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import {mapGetters} from "vuex";

export default {
    inject: ['toast'],
    mixins: [apiErrorHandler],
    data() {
        return {
            step: 0,
            file: null,
            finished: null,
            mountedComponent: false,
        }
    },
    computed: {
        ...mapGetters(['user'])
    },
    mounted() {
        this.mountedComponent = true
        this.listenProcessedEvent();
    },
    methods: {
        loadFile() {
            this.$refs.inputfile.click()
        },
        handleChangeFile(e) {
            this.file = e.target.files[0]

            if (this.file) {
                this.step = 1
            } else {
                this.$refs.inputfile.value = null
            }
        },
        sendFile() {
            this.step = 2
            const formData = new FormData()
            formData.append('file', this.file)

            CompanyInvitations
                .import(formData)
                .catch(response => {
                    if (response.validation?.file) this.toast('error', response.validation.file);
                    this.handleApiError(response);
                    this.goStart();
                })
        },
        listenProcessedEvent() {
            this.$LaravelEcho
                .private('user-notification.' + this.user.id)
                .listen('.user-notification.company-invitation.import-processed', data => {
                    this.finished = data;
                    this.step = 3
                });
        },
        goStart() {
            this.step = 0
            this.finished = null
            this.file = null
        },
    },
}
</script>

<style lang="scss" scoped>
.companyImport {
    &__header {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__title {
            text-align: center;
            margin: 20px 5px;
            font-size: 28px;
            font-weight: 700;
            line-height: 42px;
            color: #303C54;
        }

        &__subtitle {
            text-align: center;
            margin: 20px 5px;
            font-size: 28px;
            font-weight: 700;
            line-height: 42px;
        }

        &__progress {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 20px 185px 60px;

            @media (max-width: 992px) {
                padding: 10px 20px;
                margin-bottom: 80px;
            }

            &__circle {
                height: 46px;
                width: 46px;
                max-height: 46px;
                max-width: 46px;
                min-height: 46px;
                min-width: 46px;
                background: #D8DBE0;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                position: relative;

                @media (max-width: 576px) {
                    flex-direction: column;
                }

                &.active {
                    background: #005D9D;
                }

                &-text {
                    position: absolute;
                    top: calc(100% + 10px);
                    color: #303C54;
                    white-space: nowrap;
                    text-align: center;
                    font-size: 14px;
                    line-height: 21px;
                }

                & svg path, rect {
                    fill: #ffffff;
                }
            }

            &__line {
                height: 16px;
                border-radius: 4px;
                width: 100%;
                background: #D8DBE0;
                margin: 0 22px;
                overflow: hidden;

                @media (max-width: 576px) {
                    display: none;
                }

                &-bg {
                    height: 100%;
                    background: #005D9D;
                    color: #ffffff;
                    font-size: 12px;
                    transition: width .3s ease-in-out;
                }
            }


            &__arrow {
                display: none;

                @media (max-width: 576px) {
                    display: block;
                }
            }
        }

        &__modal {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 695px;
            margin: 0 0 28px;

            &__spinner {
                @media (max-width: 576px) {
                    display: none;
                }
            }

            &__title {
                text-align: center;
                margin: 40px 5px 20px;
                font-size: 22px;
                font-weight: 600;
                line-height: 33px;
                color: #303C54;

                @media (max-width: 576px) {
                    margin-top: 0;
                }
            }

            &__subtitle {
                text-align: center;
                margin: 37px 5px 8px;
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                color: #303C54;
            }

            &__text {
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: #303C54;
                word-wrap: break-word;
                margin: 0 0 9px;
            }

            &__btn {

            }
        }

        &__footer {

            &__link {
                color: #303C54;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                margin-bottom: 15px;

                &-item {
                    text-decoration: underline;
                    margin: 0 10px;
                    text-align: center;

                    &:hover {
                        color: #303C54;
                    }
                }
            }
        }
    }
}
</style>
